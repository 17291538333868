import { IExhibition } from '.'

export interface IWork {
  code: string
  caption?: string
}

export const wpiv = `Installation view: "We-presence", Yoshiaki Inoue gallery, Osaka, Japan, 2020`
export const jdCaption = `Installation view: "TOKYO POP UNDERGROUND", Jeffrey Deitch, NY, US, 2019`
const petzelCaption = `Installation view: Condo New York 2018 "Nanzuka at Petzel", Nanzuka hosted by Petzel, New York, 2018\nPhoto by Lucas Page`

export const mirrorMaterial = 'acrylic paint, grease pencil, acrylic board, wooden frame, mirror'

export const homeExhibition: IExhibition = {
  code: 'home',
  works:
  [
    {
      code: 'MT_134_large',
      caption: `Untitled\n${mirrorMaterial}\n2022`,
    },
    {
      code: 'MT_136_large',
      caption: `Untitled\n${mirrorMaterial}\n2022`,
    },
    {
      code: 'MT_135_large',
      caption: `Untitled\n${mirrorMaterial}\n2022`,
    },
    {
      code: 'wp1',
      caption: wpiv,
    },
    {
      code: 'wp2',
      caption: `Untitled\n${mirrorMaterial}\n2020`,
    },
    {
      code: 'wp3',
      caption: wpiv,
    },
    {
      code: 'wp4',
      caption: 'あのこのいる場所を探して (2012 ver.)\nInteractive installation｜projector, printer, Mac mini, software, computer mouse\n2005-, 2012 (System renewed in 2019)',
    },
    {
      code: 'wp5',
      // caption: wpiv,
    },
    {
      code: 'wp6',
      // caption: wpiv,
    },
    {
      code: 'wp7',
      // caption: wpiv,
    },
    {
      code: 'wp8',
      // caption: wpiv,
    },
    {
      code: 'wp9',
      // caption: wpiv,
    },
    {
      code: 'DSC00039',
      // caption: wpiv,
    },
    {
      code: 'DSC00024',
      caption: wpiv,
    },
    {
      code: 'MT_001_02_GH',
      // caption: jdCaption,
    },
    {
      code: 'MT_001_01_GH',
      // caption: jdCaption,
    },
    {
      code: 'MT_001_03_GH',
      caption: jdCaption,
    },
    {
      code: 'MT_079',
      caption: `Untitled\n${mirrorMaterial}\n2018`,
    },
    {
      code: 'FP_18_xxx14L',
      // caption: petzelCaption,
    },
    {
      code: 'FP_18_xxx15L',
      caption: petzelCaption,
    },
    {
      code: 'MT_080',
      caption: `Untitled\n${mirrorMaterial}\n2018`,
    },
    {
      code: 'MT_082',
      caption: `Untitled\n${mirrorMaterial}\n2018`,
    },
    {
      code: 'MT_081',
      caption: `Untitled\n${mirrorMaterial}\n2018`,
    },
    {
      code: 'FP_18_xxx18L',
      // caption: petzelCaption,
    },
    {
      code: 'FP_18_xxx20L',
      caption: petzelCaption,
    },
    {
      code: 'MT_085',
      caption: `Petzel's private room\nacrylic, pencil, color pencil on canvas\n2018`,
    },
    {
      code: 'MT_086',
      caption: `Petzel's private room\nacrylic, pencil, color pencil on canvas\n2018`,
    },
    {
      code: 'MT_083',
      caption: `Petzel's private room\nacrylic, pencil, color pencil on canvas\n2018`,
    },
    {
      code: 'FP_18_xxx19L',
      // caption: petzelCaption,
    },
    {
      code: 'FP_18_xxx21L',
      caption: petzelCaption,
    },
  ],
}
