import {
  IWork,
  mirrorMaterial,
} from '.'

export interface IExhibition {
  code: string
  title?: string
  session?: string
  venue?: string
  text?: string
  mainWork?: IWork
  works?: IWork[]
  url?: string
  courtesy?: string
  remarks?: string
  year?: string
}

export const exhibitions: IExhibition[] = [
  {
    code: '15',
    title: 'Untitled',
    mainWork: { code: 'MT_134_large' },
    works: [
      {
        code: 'MT_134_large',
        caption: `Untitled\n${mirrorMaterial}\n2022`,
      },
    ],
  },
  {
    code: '17',
    title: 'Untitled',
    mainWork: { code: 'MT_136_large' },
    works: [
      {
        code: 'MT_136_large',
        caption: `Untitled\n${mirrorMaterial}\n2022`,
      },
    ],
  },
  {
    code: '16',
    title: 'Untitled',
    mainWork: { code: 'MT_135_large' },
    works: [
      {
        code: 'MT_135_large',
        caption: `Untitled\n${mirrorMaterial}\n2022`,
      },
    ],
  },
  {
    code: '4',
    title: 'Untitled',
    mainWork: { code: 'MT_113' },
    works: [
      {
        code: 'MT_113',
        caption: `Untitled\n2021\nacrylic, grease pencil, acrylic board, wooden frame, mirror\n802mm × 688mm × 162mm`,
      },
    ],
  },
  {
    code: '1',
    // title: 'We-presence',
    title: `We-presence`,
    remarks: 'Solo exhibition',
    session: 'November 14 - December 12, 2020',
    venue: 'Yoshiaki Inoue Gallery, Osaka, JP',
    year: '2020',
    text: `\nBorn in 1982 in Tokyo, Makoto Taniguchi completed his graduate studies at the Department of Inter Media Art, Tokyo University of the Arts, and has participated in significant exhibitions including "Bishojo: Young Pretty Girls in Art History" (Aomori Museum of Art / Shizuoka Prefectural Museum of Art / Iwami Arts Museum, Shimane, a touring exhibition, 2014, Japan), and "TOKYO POP UNDERGROUND" (Jeffery Deitch, New York, Los Angeles, 2019).

The sense of "distance" that distinctively appears in Taniguchi's works conjures up the impact of the contemporary information environment, comprising video, images, virtual reality, and the user interface of personal computers and such. At the same time, through an inquiry of human perception and triggering empathy, it links the artist's internal, subjective image with images in the viewer's mind.

In such a sense of distance, I, you and it are felt so close as if being layered on top of each other, and at the same time, so far away and out of reach. In this exhibition, new paintings using mirrors, including life-size ones, will be shown on the second floor, and a video installation, which was presented in New York last year, will be reconstructed and exhibited on the third floor.\n\n\n谷口真人は、1982年東京都生まれ、東京藝術大学大学院美術研 究科先端芸術表現専攻を修了し、これまで「美少女の美術史」 (青森県立美術館 / 静岡県立美術館 / 島根県立石見美術館(巡回 展)、2014, 日本)、「TOKYO POP UNDERGROUND」(Jeffery Deitch、2019, New York, Los Angeles)などの重要な展覧会に参加しています。 谷口の作品に特徴的にあらわれる”距離”の感覚は、映像、画像、バーチャルリアリティ、パーソナルコンピュータのユーザインターフェイス等の現代的情報環境からの影響を感じさせると共に、人間の認識への問いや感情移入作用を通じて、作者の内的、主観的なイメージを、観る者の内へ呼び覚まします。\nそれはわたし、あなた、それ、が、重なりあうほど近く、手の届かないほど遠くに感じられる感覚です。本展では等身大を含めた新作の鏡を用いたペインティングを２階で、昨年ニューヨークで発表した映像を用いたインスタレーション作品を再構築し３階で発表いたします。`,
    courtesy: 'Courtesy of Yoshiaki Inoue Gallery',
    url: 'https://gallery-inoue.com/%e8%b0%b7%e5%8f%a3%e7%9c%9f%e4%ba%ba-makoto-taniguchi-%e3%80%8cwe-presence%e3%80%8d/',
    mainWork: { code: 'MT_103' },
    works: [
      {
        code: 'MT_103',
        caption: `Untitled\n2020\n2060mm x 1300mm x 370mm\nAcrylic paint, grease pencil, acrylic board, wooden frame, mirror`,
      },
      { code: 'DSC00006', caption: `あのこのいる場所を探して (2012 ver.)\nInteractive installation｜projector, printer, Mac mini, software, computer mouse\n2005-, 2012 (System renewed in 2019)` },
      { code: 'DSC00055' },
      { code: 'DSC00014' },
      { code: 'DSC00016' },
      { code: 'DSC00022' },
      { code: 'DSC00019' },
      { code: 'DSC00051' },
      { code: 'DSC00015' },
      { code: 'DSC00042' },
      { code: 'DSC00024' },
      { code: 'DSC00079' },
      { code: 'DSC00102' },
      { code: 'DSC00125' },
      { code: 'DSC00126' },
      { code: 'DSC00101' },
      { code: 'DSC00067' },
      { code: 'DSC00063' },
      { code: 'DSC00071' },
      { code: 'DSC00074' },
      { code: 'DSC00075' },
      // { code: 'DSC00093' },
      { code: 'DSC00088' },
      { code: 'DSC00091' },
      { code: 'DSC00085' },
      { code: 'DSC00086' },
      { code: 'DSC00095' },
      {
        code: 'MT_108',
        caption: `Untitled\n2020\n802mm x 688mm x 162mm\nAcrylic paint, grease pencil, acrylic board, wooden frame, mirror`,
      },
      {
        code: 'MT_100',
        caption: `Untitled\n2020\n795mm x 683mm x 150mm\nAcrylic paint, grease pencil, acrylic board, wooden frame, mirror`,
      },
    ],
  },
  {
    code: '5',
    title: 'Untitled (truth)',
    mainWork: { code: 'MT_109' },
    works: [
      {
        code: 'MT_109',
        caption: `Untitled (truth)\nacrylic, grease pencil, acrylic board, wooden frame, mirror\n938mm × 778mm × 182mm\n2020`,
      },
    ],
  },
  {
    code: '2',
    title: 'あのこのいる場所を探して (2012 ver.)',
    venue: 'Jeffrey Deitch, New York, US',
    year: '2019',
    text: `Interactive installation｜projector, printer, Mac mini, software, computer mouse
2005-, 2012 (System renewed in 2019)`,
// あのこのいる場所を探して (2012 ver.)
// at Jeffrey Deitch, New York, US
    mainWork: { code: 'MT_001_02_GH' },
    works: [
      { code: 'MT_001_02_GH' },
      { code: 'MT_001_01_GH' },
      { code: 'MT_001_03_GH' },
    ],
  },
  {
    code: '3',
    title: 'あのこのいる場所を探して (2012 ver.)',
    venue: 'SUNDAY, Tokyo, JP',
    year: '2012',
    text: `Interactive installation｜projector, printer, Mac mini, software, computer mouse\n2005-, 2012`,
    mainWork: { code: '0' },
    works: [
      { code: '0' },
      { code: '1' },
      { code: '2' },
      { code: '3' },
      { code: '4' },
      { code: '5' },
      { code: '6' },
      { code: 'MT_001' },
    ],
  },
  {
    code: '14',
    title: `Condo New York 2018 Nanzuka at Petzel`,
    session: 'June 29 - July 27, 2018',
    venue: 'Petzel Gallery, New York, US',
    year: '2018',
    mainWork: { code: 'MT_082' },
    works: [
      {
        code: 'MT_079',
        caption: `Untitled\nacrylic, paint grease pencil, acrylic board, wooden frame, mirror\n2018\n798mm x 680mm x 152mm`,
      },
      { code: 'FP_18_xxx14L' },
      {
        code: 'FP_18_xxx15L',
        caption: `Installation view:\nCondo New York 2018 "Nanzuka at Petzel", Nanzuka hosted by Petzel, New York, 2018\nPhoto by Lucas Page`,
      },
      { code: 'FP_18_xxx19L' },
      {
        code: 'FP_18_xxx21L',
        caption: `Installation view:\nCondo New York 2018 "Nanzuka at Petzel", Nanzuka hosted by Petzel, New York, 2018\nPhoto by Lucas Page`,
      },
      {
        code: '2',
        caption: `Petzel's private room\nacrylic, pencil, color pencil on canvas\n2018\n410mm x 320mm x 20mm`,
      },
      {
        code: '3',
        caption: `Petzel's private room\nacrylic, pencil, color pencil on canvas\n2018\n410mm x 320mm x 20mm`,
      },
      {
        code: '1',
        caption: `Petzel's private room\nacrylic, pencil, color pencil on canvas\n2018\n333mm x 245mm x 20mm`,
      },
      { code: 'FP_18_xxx18L' },
      {
        code: 'FP_18_xxx20L',
        caption: `Installation view:\nCondo New York 2018 "Nanzuka at Petzel", Nanzuka hosted by Petzel, New York, 2018\nPhoto by Lucas Page`,
      },
      {
        code: 'MT_081',
        caption: `Untitled\nacrylic, paint grease pencil, acrylic board, wooden frame, mirror\n2018\n620mm x 470mm x 130mm`,
      },
      {
        code: 'MT_082',
        caption: `Untitled\nacrylic, paint grease pencil, acrylic board, wooden frame, mirror\n2018\n620mm x 470mm x 130mm`,
      },
      {
        code: 'MT_080',
        caption: `Untitled\nacrylic, paint grease pencil, acrylic board, wooden frame, mirror\n2018\n620mm x 470mm x 130mm`,
      },
    ],
  },
  {
    code: '6',
    title: 'Untitled',
    mainWork: { code: 'MT_094_GH' },
    works: [
      {
        code: 'MT_094_GH',
        caption: 'Untitled\nacrylic, paint grease pencil, acrylic board, wooden frame, mirror\n2019\n633mm x 535mm x 146mm',
      },
    ],
  },
  {
    code: '7',
    title: 'Untitled',
    mainWork: { code: 'MT_097' },
    works: [
      {
        code: 'MT_097',
        caption: 'Untitled\nacrylic, paint grease pencil, acrylic board, wooden frame, mirror\n2019\n470mm x 380mm x 110mm',
      },
    ],
  },
  {
    code: '8',
    title: 'Untitled (Her)',
    mainWork: { code: 'MT_093_GH' },
    works: [
      {
        code: 'MT_093_GH',
        caption: 'Untitled\nacrylic, paint grease pencil, acrylic board, wooden frame, mirror\n2017\n795mm x 683mm x 150mm',
      },
    ],
  },
  {
    code: '9',
    title: 'Untitled（Hyacinth）',
    mainWork: { code: 'MT_078' },
    works: [
      {
        code: 'MT_078',
        caption: `Untitled（Hyacinth 5）\nacrylic, grease pencil, acrylic board, wooden frame, mirror\n2018\n798mm × 680mm × 152mm`,
      },
      {
        code: 'MT_077',
        caption: `Untitled（Hyacinth 1）\n2018\nacrylic, grease pencil, acrylic board, wooden frame, mirror\n469mm x 380mm x 108mm`,
      },
      {
        code: 'MT_074',
        caption: `Untitled（Hyacinth 2）\n2018\nacrylic, grease pencil, acrylic board, wooden frame, mirror\n469mm x 380mm x 108mm`,
      },
      {
        code: 'MT_075',
        caption: `Untitled（Hyacinth 3）\n2018\nacrylic, grease pencil, acrylic board, wooden frame, mirror\n469mm x 380mm x 108mm`,
      },
      {
        code: 'MT_076',
        caption: `Untitled（Hyacinth 4）\n2018\nacrylic, grease pencil, acrylic board, wooden frame, mirror\n469mm x 380mm x 108mm`,
      },
    ],
    text: `On the series “Untitled (Hyacinth)”

I was looking after a hyacinth bulb that Nakako had unexpectedly passed on to me.
I tended to it occasionally, refreshing the water and making paintings from time to time.

Soon after, I saw a bud forming. And as if it knew when some of the paintings were just about done, it started to bloom. I realized then that the flower had adorned the same color as the painting I made. It was the same blue.

When I first started to paint it blue, there were only the stem and a few leaves.
Even after it began to bud, and the color became recognizable, I didn’t give it much thought. It was as if the hyacinth and the painting belonged to parallel worlds that would never cross paths.

However, when I saw the flower bloom, I instantly sensed some kind of connection between the hyacinth and the painting of it, which I thought was completely natural. They seemed to have a strong connection in their presence, communicating back and forth in a cyclical way. It was as if the actual hyacinth before me trickled into the painting, and the hyacinth came to embody some parts of the painting through me.

～～～～～
I feel some of what is being depicted in my work, are certain images below, which communicate with each other in a cyclical fashion.
・Hyacinths
・Paint on an acrylic board
・An image in a mirror
・The author
Some things I draw may seem like characters, but despite their appearance, they do not necessarily represent the essence. They only show a certain “state” of things when given a form.




連作「Untitled（Hyacinth）」について

林央子さんから突然ヒヤシンスの球根を頂いて育てていた。
度々様子を見ながら、たまに水を変えたりして、絵を描いていた。

やがて蕾ができ、いくつかの絵が完成し始めるのを見計らっていたかのようなタイミングで花が咲き始めた。気づけば花は絵と同じ青色をしていた。

絵の具の青を塗り始めた時はまだ茎と葉だけの状態だった。
蕾がついて色が覗くようになっても、特に何も思わなかった。ヒヤシンスと絵とは互いに交わることのない平行世界に在るみたいに。

でも、咲いた花を見て、すぐさま、私が育てていたこのヒヤシンスと描いていた絵の間に連関があることが、当然の事のように感じられた。
ヒヤシンスと絵と私の間に、存在の不思議な循環が行われているのだと思った。
私がヒヤシンスに感じていた何かが絵に入り込むような、また絵から私を通してヒヤシンスに何かが入り込むような。`,
  },
  {
    code: '10',
    title: 'Untitled',
    remarks: 'Solo exhibition',
    session: 'March 1 - March 29, 2014',
    year: '2014',
    venue: 'NANZUKA, Tokyo, JP',
    text: `Makoto Taniguchi was born in Tokyo in 1982 and completed studies in Intermedia Art at the Graduate School of Fine Arts at Tokyo University of the Arts. Past exhibitions include Art Award Tokyo in 2007, neoneo Part 1 [Men] in 2009 (Takahashi Collection Hibiya, Tokyo), Daughters of the Lonesome Isle in 2012 (sprout curation, Tokyo), and solo exhibition Ano kono iru basho wo sagashite (2005-) in 2012 (SUNDAY, Tokyo).

Taniguchi’s works are based on the imagery of girls in Japan as they appear as characters in comics and anime, and as heartthrobs and idols. These images of girls, passed down steadily to Taniguchi since his youth in the 1980s, are acknowledged as a common, general occurrence within contemporary Japanese society. Despite the repeated cultivation of these images as encoded forms whose origins are found in currency circulation and consumption, they evoke within the viewer the feeling of a vivid, living presence. Taniguchi reiterates a dialogue with this imagery, continuing the discussion in terms of the relationship between real existence born out of “strong feelings” and “emotional involvement”, and the act of painting.

Taniguchi’s paintings, using acrylic sheets and mirrors, are some of his strongest works, bringing the efforts of his studies to fruition. The two images, one reflected in the mirror and one painted on the acrylic sheet, express the distance the artist senses between the cognitive experience and that which exists. We are unable to make out the image of the girl reflected in the mirror from straight on. Further, that image would cease to exist without the deformed image in front of it, which seems to be melting away. Coming face to face with this piece, we realize that we can never get any closer to the girl that lives in the world of the mirror. At the same time, however, we also realize that as a dialogue with the viewer the girl in the mirror will exist for eternity. Like a fleeting yet endless journey, lyrical and sweet, the artist repeatedly questions the eternal.

In regards to his work, Taniguchi says:
“When you look at anything which you sense exists, the moment when you sense that it exists it immediately becomes an ‘image’. That is to say, a mixture of memories, impressions, and image in the purely visual meaning of the word: something that can only truly be understood by oneself. In an attempt to confirm that the images do indeed exist, we try to conjure them before our eyes by painting or creating objects. When I try to draw the interior ‘images’ which though invisible to the eye surely do exist, the dazzling brightness and the ephemeral nature of that existence surges forth, and I start to think about my own ideas of ‘existence’ and my views on life.”


谷口真人は、1982年東京都生まれ、東京藝術大学 大学院美術研究科 先端芸術表現専攻を修了し、これまで2007年「アートアワード東京」、2009年「neoneo 展part1[男子]」（高橋コレクション日比谷、東京）、2012年「Daughters of the Lonesome Isle展」(sprout curation、東京)、2012年個展「あのこのいる場所をさがして(2005-)」(SUNDAY、東京)などの展覧会に参加してきました。

谷口が描く作品は、漫画やアニメーション、アイドルなどキャラクター化された日本の少女像が基になっています。こうした少女像は、谷口が少年期を過ごした８０年代から脈々と受け継がれ、流通や消費に由来する記号化された造形として培養を繰り返しながらも、生々しい存在感を感じさせるイメージとして、現代の日本社会の中では一般的な存在として認知されています。谷口は、こうしたイメージとの対話を繰り返しながら、「思い入れ」や「感情移入」によって生まれる現実存在と、絵を描く行為の関係性について、考察を続けてきました。

谷口のアクリル板と鏡を利用した絵画は、こうした研究の成果を結実させた秀作です。鏡に映るイメージとアクリル板上に置かれた絵具は、そのまま作家が感じている認識と実存との距離を表します。鏡に映る少女像は作品の正面からは認識することができません。また、溶け出してしまったかのようにデフォルメされた手前の絵具を抜きには存在する事ができません。私たちは、この作品を前にして、鏡の世界に住む少女にはこれ以上永遠に近づけないことに気付くでしょう。しかし、同時に鏡の少女が鑑賞者との対話にとって永遠に存在し続けることにも気付くのです。それは、儚くも、叙情的で甘美な終わりの無い旅のようであり、作家が繰り返す永遠の問いなのです。

谷口は、自らの作品について、次のように語っています。
「私たちが存在すると感じる全てのものは、見て、存在すると感じた瞬間に、“イメージ”になる。それは記憶や印象、ビジュアルという意味でのイメージなどが混ざったような自分にしかわからないもの。それが確かにあることを確認しようと、絵を描いたり物をつくったりしてなんとか目の前にあらわそうとする。そのように目には見えないけど確かにある内なる”イメージ”を描こうとする時、その存在の儚さやまぶしさが沸き上がってきて、自らの生命観や”存在する”ということについて思いを至らせる。」`,
mainWork: { code: '_MG_0492' },
    works: [
      { code: '_MG_0492' },
      { code: '_MG_0503' },
      { code: '_MG_0515' },
      { code: '_MG_0519' },
      { code: '_MG_0532' },
    ],
  },
  {
    code: '11',
    title: 'Summer 2011',
    mainWork: { code: 'mm059' },
    works: [
      { code: 'mm059' },
      { code: 'mm061' },
      { code: 'mm063' },
      { code: 'MT_014', caption: `Summer 2011\n2011\nacrylic and glitter on canvas\n158mm × 229mm × 21mm` },
      { code: 'MT_018', caption: `Summer 2011\n2011\nacrylic and glitter on canvas\n158mm × 229mm × 21mm` },
      { code: 'MT_015', caption: `Summer 2011\n2011\nacrylic and glitter on canvas\n158mm × 229mm × 21mm` },
      { code: 'MT_016', caption: `Summer 2011\n2011\nacrylic and glitter on canvas\n158mm × 229mm × 21mm` },
      { code: 'MT_017', caption: `Summer 2011\n2011\nacrylic and glitter on canvas\n158mm × 229mm × 21mm` },
    ],
  },
  {
    code: '12',
    title: 'Untitled',
    mainWork: { code: '1' },
    works: [
      { code: '1', caption: `Untitled\nCharcoal on paper\n2010\n768mm x 541mm` },
      { code: 'IMGP7836', caption: `Untitled\nebony pencil, gel marker, conte, marker on paper\n2010\n840mm x 715mm` },
      { code: 'IMGP7831', caption: `Untitled\nconte, oil pastel, marker, pastel, white wax pastel, gesso on canvas\n2010\n801mm × 650mm` },
      // { code: '4' },
    ],
  },
  {
    code: '13',
    title: 'Lost in transformation',
    mainWork: { code: 'IMGP5349' },
    works: [
      { code: 'IMGP5349', caption: `Lost in transformation\n2007\nmixed media\n445mm x 335mm x 180 mm each` },
    ],
  },
]
