








































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

interface ILink {
  label: string
  href: string
  external?: boolean
}

@Component({})
export default class App extends Vue {
  private drawer: boolean = false
  private width: number = 1400

  private labelStyle: any = {
    fontSize: '15px',
    color: 'black',
    fontWeight: 400,
    margin: '0em 0.5em',
    fontFamily: 'serif',
    letterSpacing: '1px',
  }

  private categoryLinks: ILink[] = [
    // {
    //   href: 'http://blog.makototaniguchi.com/?cid=1',
    //   label: 'News',
    // },
    {
      href: '/works',
      label: 'Works',
    },
    {
      href: '/cv',
      label: 'CV',
      // http://blog.makototaniguchi.com/?pid=1
    },
    {
      href: 'mailto:info@makototaniguchi.com',
      label: 'Contact',
      external: true,
    },
  ]

  private socialLinks: ILink[] = [
    {
      href: 'https://www.instagram.com/makototaniguchi',
      label: 'Instagram',
    },
    // {
    //   href: 'https://www.facebook.com/makoto.taniguchi.official',
    //   label: 'Facebook',
    // },
    {
      href: 'http://twitter.com/#!/makototaniguchi',
      label: 'Twitter',
    },
  ]

  private scrollToTop () {
    if (this.$route.path !== '/') {
      this.$router.push('/')
      return
    }

    const element = document.documentElement
    const height = element.scrollHeight - element.clientHeight
    window.scrollBy({
      left: 0,
      top: -height,
      behavior: 'smooth',
    })
  }
}
