export interface IBiography {
  title: string
  values: string[]
}

export interface IProfile {
  name: string
  introduction: string[]
  biographies: IBiography[]
}

const biographiesEn: IBiography[] = [
  {
    title: `Education`,
    values: [
      `2005　Graduated with B.F.A in Information Design, Musashino Art University`,
      `2007　Graduated with M.F.A in Inter Media Art, Tokyo National University of Fine Arts and Music`,
    ],
  },
  {
    title: `Solo exhibitions`,
    values: [
      `2022  "Where is your ♡?", NANZUKA UNDERGROUND, Tokyo`,
      `2020  "We-presence", Yoshiaki Inoue Gallery, Osaka`,
      `2015  "you", AISHONANZUKA, HongKong`,
      `2014  "Untitled", NANZUKA, Tokyo`,
      `2012  "Anoko no iru basho wo sagashite ", SUNDAY, Tokyo`,
      `2011  "ANIME", SUNDAY ISSUE, Tokyo`,
      `2011  "Summer 2011", Lamp harajuku B1 gallery, Tokyo`,
      `2009  "Makoto Taniguchi: Your Cinderella", LaiRai, Tokyo`,
    ],
  },
  {
    title: `Group exhibitions`,
    values: [
      `2021  "New Works", Komagome SOKO, Tokyo, Japan`,
      `2020  "JP POP UNDERGROUND", Shinsaibashi PARCO 14F, Osaka, Japan`,
      `2019  "TOKYO POP UNDERGROUND", Jeffrey Deitch, New York, United States`,
      `2019  "Bishojo: Young Pretty Girls in Art History", MoNTUE北師美術館, Taipei, Taiwan`,
      `2018  "Condo New York: Nanzuka at Petzel - Haroshi, Makoto Taniguchi, Masato Mori", Petzel, New York, United States`,
      `2017  "INFRA 2017", YAMAMOTO GENDAI, Tokyo, Japan`,
      `2017  "Spring Fever", Komagome SOKO, Tokyo, Japan`,
      `2016  "Takashi Murakami's Superflat Collection - From Shōhaku and Rosanjin to Anselm Kiefer-", Yokohama Art Museum, Kanagawa, Japan`,
      `2014  "Bishojo: Young Pretty Girls in Art History", Aomori Museum of Art / Shizuoka Prefectural Museum of Art / Iwami Art Museum`,
      `2013  "The Power of Manga:Osamu Tezuka and Shotaro Ishinomori", MUSEUM OF CONTEMPORARY ART TOKYO`,
      `2011-2012　"Daughters of the Lonesome Isle Marlene MARINO / Makoto TANIGUCHI", SPROUT Curation, Tokyo`,
      `2011　"NATSU NO GEIJUTSUSAI", Mitsukoshi Nihonbashi, Tokyo`,
      `2011　"Slash3 Emi Otaguro/Makoto Taniguchi", island medium, Tokyo`,
      `2009　"neoneo ten", Takahashi Collection Hibiy, Tokyo`,
      `2007　"ARTZONE EYE 01", art project room ARTZONE, Kyoto`,
      `2007　"Toshiki Yagisawa Selection Ver.1.0" , Gallery Countach, Tokyo`,
      `2007　"Art Award Tokyo 2007" , Gyoukou-Chika Gallery, Tokyo`,
      `2007　"Project The Projectors", Tokyo National University of Fine Arts and Music, Ibaraki`,
    ],
  },
  {
    title: `Special Projects`,
    values: [
      `2011　"Girls of writtenafterwards by Makoto Taniguchi", Lamp harajuku, Tokyo`,
      `2010　"OPEN STUDIO", Artist's Studio, Tokyo`,
    ],
  },
  {
    title: `Bibliography`,
    values: [
      `2014　"REVIEWS",『BT/Bijutsu Techo』May, bijyutsu syuppan-sya`,
      `2013　"ARTNAVI",『BT/Bijutsu Techo』February-May, bijyutsu syuppan-sya`,
      `2012　"ACRYLICS WORLD 68",『BT/Bijutsu Techo』March, bijyutsu syuppan-sya`,
      `2012　Kiki Kudo "Art Tripper",『NYLON JAPAN』February, Trans Media`,
      `2012　"Nakako Hayashi "What's Art?"、『GINZA』February、Magazine House`,
      `2012　Nakako Hayashi, 『SO-EN』February, bunka syuppan-kyoku`,
      `2011　"PICK UP ARTIST"『+ Designing』, November, Mainichi Communications`,
      `2011　"VISON City TOKYO", 『VISION』September`,
      `2011　"movement"『Web Designing』, June, Mainichi Communications`,
      `2010　"Artists' Manifestos: Voices of the New Decade"『BT/Bijutsu Techo』, May, bijyutsu syuppan-sya`,
      `2009　Noriko Miyamura, 『SWITCH』, February, SWITCH publishing`,
      `2007　Noriko Miyamura "Revew : Makoto Taniguchi, Touko Kodaira"『BT/Bijutsu Techo』, August, bijyutsu syuppan-sya`,
      `2007　『ART AWARD TOKYO 2007』, ART AWARD TOKYO steering committee, artbeat publishers`,
    ],
  },
]

const biographiesJa: IBiography[] = [
  {
    title: `個展`,
    values: [
      `2022  「Where is your ♡?」、NANZUKA UNDERGROUND、東京`,
      `2020  「We-presence」、Yoshiaki Inoue Gallery、大阪`,
      `2015  「you」、AISHONANZUKA, 香港`,
      `2014  「Untitled」、NANZUKA、東京`,
      `2012  「あのこのいる場所をさがして」、SUNDAY、東京`,
      `2011  「アニメ」、SUNDAY ISSUE、東京`,
      `2011  「Summer 2011」、Lamp harajuku B1 gallery、東京`,
      `2009  「Makoto Taniguchi: Your Cinderella」、来来、東京`,
    ],
  },
  {
    title: `グループ展`,
    values: [
      `2021  「New Works」駒込倉庫、東京、日本`,
      `2020  「JP POP UNDERGROUND」心斎橋PARCO 14F、大阪、日本`,
      `2019  「TOKYO POP UNDERGROUND」, Jeffrey Deitch, New York, United States`,
      `2019  「Bishojo: Young Pretty Girls in Art History」, MoNTUE北師美術館, Taipei, Taiwan`,
      `2018　「めがねと旅する美術展　江戸時代から現代まで「みる」ことの探求」、青森県立美術館、島根県立石見美術館、静岡県立美術館、東京、島根、静岡`,
      `2018　 「Condo New York: Nanzuka at Petzel - Haroshi, Makoto Taniguchi, Masato Mori」、Petzel、ニューヨーク`,
      `2017  「インフラ INFRA 2017」山本現代、東京`,
      `2017  「Spring Fever」駒込倉庫、東京`,
      `2016  「Takashi Murakami's Superflat Collection - From Shōhaku and Rosanjin to Anselm Kiefer-」、横浜美術館、神奈川`,
      `2014　「美少女の美術史」、青森県立美術館 / 静岡県立美術館 / 島根県立石見美術館(巡回)`,
      `2013　「手塚治虫×石ノ森章太郎 マンガのちから」、東京都現代美術館、広島県立歴史博物館、大阪歴史博物館、山梨県立博物館、宮城県美術館、 東京、広島、大阪、山梨、宮城`,
      `2011-2012　「Daughters of the Lonesome Isle Marlene MARINO / Makoto TANIGUCHI」、SPROUT Curation、東京`,
      `2011　「夏の芸術祭 次代を担う若手作家作品展」、日本橋三越本店、東京`,
      `2011　「Slash3 Emi Otaguro/Makoto Taniguchi」、island medium、東京`,
      `2009　「neoneo 展part1[男子]」、高橋コレクション日比谷、東京`,
      `2007　「ARTZONE EYE 01」、art project room ARTZONE、京都`,
      `2007　「Toshiki Yagisawa Selection Ver.1.0」、Gallery Countach、東京`,
      `2007　「アートアワード東京」、丸の内行幸地下ギャラリー、東京`,
    ],
  },
  {
    title: `アートフェア`,
    values: [
      `2019　 「Art Basel in Hong Kong 2019」、Convention & Exhibition Centre、香港`,
      `2019　 「Taipei Dangdai」、Taipei Nangang Exhibition Center、台北`,
      `2018　 「Art Basel in Hong Kong 2018」、Convention & Exhibition Centre、香港`,
      `2017　 「Art Basel in Hong Kong 2017」、Convention & Exhibition Centre、香港`,
      `2016　 「ART COLOGNE 2016」、Koelnmesse、ケルン`,
      `2016　 「Art Osaka」、大阪`,
      `2016　 「Art Basel in Hong Kong 2016」、Convention & Exhibition Centre、香港`,
      `2015　 「Art Stage Singapore 2015」、Marina Bay Sands 、シンガポール`,
      `2014　 「Art Basel in Hong Kong 2014」、Convention & Exhibition Centre、香港`,
      `2014　 「アートフェア東京」、東京国際フォーラム、東京`,
      `2014 　「Art Stage Singapore 2014」、Marina Bay Sands 、シンガポール`,
      `2013　 「Art Basel in Hong Kong 2013」、Convention & Exhibition Centre、香港`,
    ],
  },
  {
    title: `プロジェクト`,
    values: [
      `2018　「"Hyacinth" for HYACINTH REVOLUTION here and there vol.13」`,
      `2018　「"The glow" for Sofia Coppola "The Beguild"」`,
      `2017　サウンド・パフォーマンス「DJ Paypal × Makoto Taniguchi　”リアニメーション」、山本現代、東京`,
      `2011　ウィンドウインスタレーション「谷口真人の描くwrittenafterwards の女の子」（writtenafterwards との共作）、Lamp harajuku、東京`,
      `2010　「OPEN STUDIO」、作家スタジオ、東京`,
    ],
  },
  {
    title: `主な出版物寄稿`,
    values: [
      `2018　「here and there vol.13」、林央子、Nieves`,
      `2015　『欲望するインタビュー』  、「欲望するインタビュー」、原田優輝、Pヴァイン`,
      `2013　「仕事や人生や未来について考える時にアーティストが語ること」、野崎武夫、フィルムアート`,
    ],
  },
  {
    title: `出版物掲載`,
    values: [
      `2018　『めがねと旅する美術」、めがねと旅する美術展実行委員会、青幻舎`,
      `2016　『村上隆のスーパーフラット・コレクション　―蕭白、魯山人からキーファーまで―』、村上隆、Kaikai Kiki Co., Ltd.`,
      `2014　『美少女の美術史ー浮世絵からポップカルチャー、現代美術にみる”少女”のかたち』、美少女の美術史展実行委員会、青幻舎`,
      `2014  『Asia Anarchy Alliance』、Project Fulfil Art International Co., Ltd`,
      `2014　『美術手帖』5月号、「REVIEWS」、美術出版社`,
      `2013　『手塚治虫×石ノ森章太郎　マンガのちから』、藤子不二雄A、宮武弘（福島県立美術館）、澤井浩一（大阪歴史博物館）、三上満良（宮城県美術館）、西村直城（広島県立歴史博物館）、加藤弘子（東京都現代美術館）、NHKプロモーション`,
      `2013　『美術手帖』「ARTNAVI」２月号～５月号 表紙、美術出版社`,
      `2012　『美術手帖』3月号、「ACRYLICS WORLD 68」、美術出版社`,
      `2012　『NYLON JAPAN』2月号、工藤キキ「KIKI のArt Tripper」、トランスメディア`,
      `2012　『GINZA』2月号、「林央子のWhat´s Art?」、マガジンハウス`,
      `2012　『装苑』2月号、「林央子が考察するガーリーカルチャー」、文化出版局`,
      `2011　『+DESIGNING』11月号、「PICK UP ARTIST」、毎日コミュニケーションズ`,
      `2011　『VISION』9月号、「VISON City TOKYO」`,
      `2011　『Web Designing』6月号、「movement」、毎日コミュニケーションズ`,
      `2010　『美術手帖』6月号、「新世代アーティスト宣言！」、美術出版社`,
      `2009　『SWITCH』2月号、宮村周子、スイッチパブリッシング`,
      `2007　『美術手帖』8月号、宮村周子「ギャラリーレビュー　谷口真人・小平透子」、美術出版社`,
      `2007　『アートアワード東京2007カタログ』、アートアワード運営室、アートビートパブリッシャーズ`,
    ],
  },
  {
    title: `メディア`,
    values: [
      `2012　ゲスト出演　SHIBUYA-FM 78.4MHz「Magnet Girl Radio」、 SHIBUYA-FM`,
      `2010　『TOKYO FASHION EXPRESS』、NHK ワールドTV`,
    ],
  },
]

export const profiles: IProfile[] = [
  {
    name: 'Makoto Taniguchi',
    introduction: [
      'born: 1982 in Tokyo',
      'lives and works in Tokyo, Japan',
    ],
    biographies: biographiesEn,
  },
  {
    name: '谷口 真人',
    introduction: [
      '1982年生まれ',
      '東京を拠点に活動',
      '東京芸術大学大学院 美術研究科先端芸術表現専攻修了',
    ],
    biographies: biographiesJa,
  },
]
