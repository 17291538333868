var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({
      fontSize: '22px',
      fontStyle: 'oblique',
      fontWeight: 600,
    }),domProps:{"textContent":_vm._s(_vm.exhibition.title)}}),(_vm.exhibition.remarks)?_c('div',{style:({
      fontSize: '17px',
    }),domProps:{"textContent":_vm._s(_vm.exhibition.remarks)}}):_vm._e(),_c('div',{style:({
      fontSize: '17px',
    }),domProps:{"textContent":_vm._s(_vm.exhibition.session)}}),_c('div',{style:({
      fontSize: '17px',
    }),domProps:{"textContent":_vm._s(_vm.exhibition.venue)}}),_c('div',{style:({
      marginTop: '1em',
      whiteSpace: 'pre-wrap',
      textAlign: 'justify',
    }),domProps:{"textContent":_vm._s(_vm.exhibition.text)}}),(_vm.exhibition.courtesy)?_c('div',{style:({
      whiteSpace: 'pre-wrap',
      textAlign: 'right',
      fontSize: '12px',
    }),domProps:{"textContent":_vm._s(_vm.exhibition.courtesy)}}):_vm._e(),_c('VerticalWorks',{style:({
      marginTop: '2em',
    }),attrs:{"exhibition":_vm.exhibition}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }