var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.exhibition.works),function(work){return _c('div',{key:work.code,style:({
      marginBottom: '3em',
    })},[_c('v-img',{attrs:{"contain":"","src":("/img/exhibitions/" + (_vm.exhibition.code) + "/" + (work.code) + ".jpg"),"lazy-src":("/img/exhibitions/" + (_vm.exhibition.code) + "/small/" + (work.code) + ".jpg"),"max-height":"1200"}}),(work.caption)?_c('div',{style:({
        marginTop: '0.8em',
        marginRight: '0.4em',
        whiteSpace: 'pre-wrap',
        textAlign_: 'center',
        letterSpacing: '1px',
        color: '#555555',
        fontSize: '18px',
        lineHeight: '1.3em',
        fontWeight: 400,
        fontFamily: 'serif',
      }),domProps:{"textContent":_vm._s(work.caption)}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }