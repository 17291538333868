



































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { IExhibition } from '@/store'

@Component({})
export default class VerticalWorks extends Vue {
  @Prop({})
  public exhibition: IExhibition
}

