var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{directives:[{name:"masonry",rawName:"v-masonry"}]},_vm._l((_vm.exhibitions),function(exhibition){return _c('v-col',{key:exhibition.title,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[(exhibition.mainWork)?_c('v-img',{style:({
          cursor: 'pointer',
        }),attrs:{"src":("/img/exhibitions/" + (exhibition.code) + "/" + (exhibition.mainWork.code) + ".jpg")},on:{"click":function($event){_vm.$router.push(_vm.exhibitionDetailUrl(exhibition))},"load":function($event){return _vm.$redrawVueMasonry()}}}):_vm._e(),_c('router-link',{style:({
          marginTop: '0.4em',
          color: 'black',
          display: 'block',
          fontStyle: 'oblique',
        }),attrs:{"to":_vm.exhibitionDetailUrl(exhibition)},domProps:{"textContent":_vm._s(_vm.title(exhibition))}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }