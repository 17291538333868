var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-navigation-drawer',{attrs:{"app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',_vm._l((_vm.categoryLinks),function(link){return _c('v-list-item',{key:link.label},[(link.external)?_c('a',{style:(_vm.labelStyle),attrs:{"href":link.href,"target":"_blank"},domProps:{"textContent":_vm._s(link.label)}}):_c('router-link',{style:(_vm.labelStyle),attrs:{"to":link.href,"title":link.label},domProps:{"textContent":_vm._s(link.label)}})],1)}),1),_c('v-divider'),_c('v-list',_vm._l((_vm.socialLinks),function(link){return _c('v-list-item',{key:link.label,attrs:{"link":"","href":link.href,"target":"_blank"},domProps:{"textContent":_vm._s(link.label)}})}),1)],1),_c('v-app-bar',{attrs:{"app":"","flat":"","hide-on-scroll":"","color":"white","fixed":"","scroll-threshold":"56"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mdAndUp),expression:"!$vuetify.breakpoint.mdAndUp"}],attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("menu")])],1),_c('v-spacer'),_c('div',{style:({
        display: 'flex',
        alignItems: 'center',
        width: (((_vm.width - 36.).toString()) + "px"),
      })},[_c('v-spacer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mdAndUp),expression:"!$vuetify.breakpoint.mdAndUp"}]}),_c('div',{style:({
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }),on:{"click":_vm.scrollToTop}},[_c('div',{style:({
            marginTop_: '4px',
            fontSize: '18px',
            letterSpacing: '2px',
            fontWeight: 500,
            fontFamily: 'serif',
          }),domProps:{"textContent":_vm._s('Makoto Taniguchi')}}),_c('div',{style:({
            marginLeft: '1em',
            fontSize: '18px',
            fontWeight: 500,
            letterSpacing: '4px',
            fontFamily: "'游明朝','Yu Mincho',YuMincho,'Hiragino Mincho Pro',serif",
          }),domProps:{"textContent":_vm._s('谷口真人')}})]),_c('v-spacer'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}]},_vm._l((_vm.categoryLinks),function(link){return _c('span',{key:link.label},[(link.external)?_c('a',{style:(_vm.labelStyle),attrs:{"href":link.href,"target":"_blank"},domProps:{"textContent":_vm._s(link.label)}}):_c('router-link',{style:(_vm.labelStyle),attrs:{"to":link.href,"title":link.label},domProps:{"textContent":_vm._s(link.label)}})],1)}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],style:({
          marginLeft: '1em',
        })},_vm._l((_vm.socialLinks),function(link){return _c('a',{key:link.label,style:(_vm.labelStyle),attrs:{"href":link.href,"title":link.label,"target":"_blank"},domProps:{"textContent":_vm._s(link.label)}})}),0)],1),_c('v-spacer')],1),_c('v-main',[_c('div',{style:({
        maxWidth: ((_vm.width.toString()) + "px"),
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '1em',
        paddingRight: '1em',
      })},[_c('router-view'),_c('div',{style:({
          marginTop: '1em',
          marginBottom: '1em',
          textAlign_: 'right',
          letterSpacing: '1px',
        }),domProps:{"textContent":_vm._s('©Makoto Taniguchi')}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }