var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    letterSpacing: '1px',
  })},_vm._l((_vm.profiles),function(profile){return _c('div',{key:profile.name,style:({
      marginTop: '4em',
    })},[_c('div',{style:({
        fontWeight: 600,
      }),domProps:{"textContent":_vm._s(profile.name)}}),_vm._l((profile.introduction),function(i){return _c('div',{key:i,style:({
        marginTop: '1em',
      }),domProps:{"textContent":_vm._s(i)}})}),_vm._l((profile.biographies),function(biography){return _c('div',{key:biography.title,style:({
        marginTop: '2em',
      })},[_c('div',{style:({
          marginTop: '1em',
          fontSize: '13px',
          fontWeight: '600',
        }),domProps:{"textContent":_vm._s(biography.title)}}),_vm._l((biography.values),function(value){return _c('div',{key:value,style:({
          marginTop: '1em',
          fontSize: '13px',
        }),domProps:{"textContent":_vm._s(value)}})})],2)})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }