





































import { Vue, Component, Prop } from 'vue-property-decorator'
import { IExhibition } from '@/store'

@Component({})
export default class Works extends Vue {
  @Prop({}) private exhibitions: IExhibition[]

  private exhibitionDetailUrl (exhibition: IExhibition): string {
    return `/works/${exhibition.code}`
  }

  private title (exhibition: IExhibition): string {
    const lines: string[] = []
    lines.push(exhibition.remarks ? exhibition.remarks + ' ' + exhibition.title : exhibition.title)
    if (exhibition.venue) {
      lines.push(exhibition.venue)
    }
    if (exhibition.year) {
      lines.push(exhibition.year)
    }
    return lines.join(', ')
  }

  private mounted () {
    // new Masonry( '.masonry', {
    //   itemSelector: `[class*='col-']`,
    // })
  }
}
