import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

import VerticalWorks from '@/views/VerticalWorks.vue'
import Works from '@/views/Works.vue'
import CV from '@/views/CV.vue'
import ExhibitionDetail from '@/views/ExhibitionDetail.vue'

import {
  homeExhibition,
  exhibitions,
} from '@/store'

Vue.use(Router)

const routes: RouteConfig[] = [
  {
    path: '/',
    component: VerticalWorks,
    props: {
      exhibition: homeExhibition,
    },
  },
  {
    path: '/works',
    component: Works,
    props: {
      exhibitions: exhibitions,
    },
  },
  {
    path: '/works/:code',
    component: ExhibitionDetail,
    props: route => ({ exhibition: exhibitions.find(e => e.code === route.params.code) }),
  },
  {
    path: '/cv',
    component: CV,
  },
]

const router = new Router({
  mode: 'history',
  routes: routes,
})

export default router
