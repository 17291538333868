import Vue from 'vue'
import Vuetify from 'vuetify'
import { VueMasonryPlugin } from 'vue-masonry'

import router from '@/router'
import App from './App.vue'

import './styles.scss'

Vue.config.productionTip = false
Vue.use(Vuetify)
Vue.use(VueMasonryPlugin)

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
  Vue.config.silent = true
}

new Vue({
  router: router,
  components: {
    App: App,
  },
  render: h => h('App'),
  vuetify: new Vuetify(),
}).$mount('#app')
