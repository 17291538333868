








































































import { IProfile, profiles } from '@/store/cv'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({})
export default class CV extends Vue {
  private get profiles (): IProfile[] {
    return profiles
  }
}
