























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { IExhibition } from '@/store'

import VerticalWorks from './VerticalWorks.vue'

@Component({
  components: {
    VerticalWorks: VerticalWorks,
  },
})
export default class ExhibitionDetail extends Vue {
  @Prop({}) private exhibition: IExhibition
}

